.input-label {
  font-size: 13px;
  color: #3F3F3F;
  font-weight: 600;
  margin-bottom: 5px; }
  .input-label .form-require-circle {
    width: 6px;
    height: 6px;
    background-color: #008EFB;
    border-radius: 100px;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 1px; }
