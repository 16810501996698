.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  max-width: 34.375rem;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  -ms-flex-direction: column;
  justify-content: center;
  -ms-flex-pack: center;
  height: 100vh; }
  .container > form {
    background: white;
    padding: 2.5rem 1.875rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    -ms-flex-pack: center; }
    .container > form > h1 {
      text-align: center;
      margin-bottom: 3.125rem; }
    .container > form > button {
      margin-top: 0.625rem;
      background: #008EFB;
      padding: 0.9375rem 1.25rem;
      font-size: 0.875rem;
      color: white; }
