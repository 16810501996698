body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
button {
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
h1, h2, h3, h4, h5 {
  margin: 0;
  color: #000000;
}

p, a, li {
  margin: 0;
  color: #3d3d3d;
}

li {
  font-size: 0.875rem;
}

p, a {
  font-size: 0.875rem;
  font-weight: 500;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App_template__1b5z5 {
  width: 100%; }

.App_template__1b5z5 .App_routes__3W2tN {
  width: 95%;
  margin: 0 auto;
  position: relative;
  top: 5rem; }

.SignIn_container__YCPbl {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  max-width: 34.375rem;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -ms-flex-pack: center;
  height: 100vh; }

.SignIn_container__YCPbl > form {
  background: white;
  padding: 2.5rem 1.875rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -ms-flex-pack: center; }

.SignIn_container__YCPbl > form > h1 {
  text-align: center;
  margin-bottom: 3.125rem; }

.SignIn_container__YCPbl > form > button {
  margin-top: 0.625rem;
  background: #008EFB;
  padding: 0.9375rem 1.25rem;
  font-size: 0.875rem;
  color: white; }

.validation-wrapper {
  margin-bottom: 2.5rem; }
  .validation-wrapper .warning-text {
    color: #FF4A22;
    position: absolute; }

.input-label {
  font-size: 13px;
  color: #3F3F3F;
  font-weight: 600;
  margin-bottom: 5px; }
  .input-label .form-require-circle {
    width: 6px;
    height: 6px;
    background-color: #008EFB;
    border-radius: 100px;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 1px; }

.InputBox_warning-text__1-oRh {
  color: red;
  position: absolute; }

.InputBox_input-label__1FwaM {
  font-size: 13px;
  color: #3F3F3F;
  font-weight: 600;
  margin-bottom: 5px; }

.InputBox_form-require-circle__2b1O5 {
  width: 6px;
  height: 6px;
  background-color: #0072ce;
  border-radius: 100px;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 1px; }

.InputBox_input-disabled__2JCFN {
  background: #ebebe4 !important;
  color: #9a9a9a !important;
  cursor: default !important;
  pointer-events: none !important;
  opacity: .45 !important; }

.InputBox_marginLeft__2bA2Y {
  margin-left: 20px; }

.InputBox_fit__38zVL {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.InputBox_input-box__1GSWG {
  width: 100%;
  min-height: 50px;
  position: relative;
  background: #FAFAFA;
  width: 100%;
  padding: 13px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #3D3D3D;
  font-family: 'Montserrat';
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify;
  -webkit-align-items: center;
          align-items: center;
  -ms-flex-align: center; }

.InputBox_input-box__1GSWG input {
  width: 100%;
  border: none; }

.InputBox_input-box__1GSWG input:focus {
  outline: none; }

.InputBox_input-box__1GSWG input::-webkit-input-placeholder {
  color: #929292;
  opacity: 1;
  /* Firefox */ }

.InputBox_input-box__1GSWG input::-ms-input-placeholder {
  color: #929292;
  opacity: 1;
  /* Firefox */ }

.InputBox_input-box__1GSWG input::placeholder {
  color: #929292;
  opacity: 1;
  /* Firefox */ }

.InputBox_input-box__1GSWG input:-ms-input-placeholder, .InputBox_input-box__1GSWG input::-ms-input-placeholder {
  color: #929292; }

.InputBox_input-box__1GSWG span {
  -webkit-flex: none;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: none;
  color: #9e9e9e; }

.InputBox_input-box__1GSWG.InputBox_error__1ZKzT {
  border-color: #FF4A22; }

.InputBox_input-box__1GSWG.InputBox_focus__1JU5m {
  border-color: #008EFB; }

.InputBox_input-box__1GSWG.InputBox_disabled__cPG2r {
  pointer-events: none;
  border-color: #bdbdbd;
  color: #3D3D3D;
  background: #eeeeee; }

.InputBox_input-box__1GSWG.InputBox_disabled__cPG2r input {
  background: #eeeeee; }

.InputBox_input-box__1GSWG.InputBox_disabled__cPG2r input::-webkit-input-placeholder {
  color: #bdbdbd;
  opacity: 1;
  /* Firefox */ }

.InputBox_input-box__1GSWG.InputBox_disabled__cPG2r input::-ms-input-placeholder {
  color: #bdbdbd;
  opacity: 1;
  /* Firefox */ }

.InputBox_input-box__1GSWG.InputBox_disabled__cPG2r input::placeholder {
  color: #bdbdbd;
  opacity: 1;
  /* Firefox */ }

.InputBox_input-box__1GSWG.InputBox_disabled__cPG2r input:-ms-input-placeholder, .InputBox_input-box__1GSWG.InputBox_disabled__cPG2r input::-ms-input-placeholder {
  color: #bdbdbd; }

.Input_warning-text__3A_6u {
  color: red;
  position: absolute; }

.Input_input-label__3knBl {
  font-size: 13px;
  color: #3F3F3F;
  font-weight: 600;
  margin-bottom: 5px; }

.Input_form-require-circle__NGh9g {
  width: 6px;
  height: 6px;
  background-color: #0072ce;
  border-radius: 100px;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 1px; }

.Input_input-disabled__spGCo {
  background: #ebebe4 !important;
  color: #9a9a9a !important;
  cursor: default !important;
  pointer-events: none !important;
  opacity: .45 !important; }

.Input_marginLeft__1_KGH {
  margin-left: 20px; }

.Input_fit__3c92l {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.Input_input-box__1q83J {
  width: 100%;
  min-height: 50px;
  position: relative;
  background: #FAFAFA;
  width: 100%;
  padding: 13px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #3D3D3D;
  font-family: 'Montserrat';
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify;
  -webkit-align-items: center;
          align-items: center;
  -ms-flex-align: center; }

.Input_input-box__1q83J input {
  width: 100%;
  border: none; }

.Input_input-box__1q83J input:focus {
  outline: none; }

.Input_input-box__1q83J input::-webkit-input-placeholder {
  color: #929292;
  opacity: 1;
  /* Firefox */ }

.Input_input-box__1q83J input::-ms-input-placeholder {
  color: #929292;
  opacity: 1;
  /* Firefox */ }

.Input_input-box__1q83J input::placeholder {
  color: #929292;
  opacity: 1;
  /* Firefox */ }

.Input_input-box__1q83J input:-ms-input-placeholder, .Input_input-box__1q83J input::-ms-input-placeholder {
  color: #929292; }

.Input_input-box__1q83J span {
  -webkit-flex: none;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: none;
  color: #9e9e9e; }

.Input_input-box__1q83J.Input_error__3eHbk {
  border-color: #FF4A22; }

.Input_input-box__1q83J.Input_focus__16Xjj {
  border-color: #008EFB; }

.Input_input-box__1q83J.Input_disabled__22_7z {
  pointer-events: none;
  border-color: #bdbdbd;
  color: #3D3D3D;
  background: #eeeeee; }

.Input_input-box__1q83J.Input_disabled__22_7z input {
  background: #eeeeee; }

.Input_input-box__1q83J.Input_disabled__22_7z input::-webkit-input-placeholder {
  color: #bdbdbd;
  opacity: 1;
  /* Firefox */ }

.Input_input-box__1q83J.Input_disabled__22_7z input::-ms-input-placeholder {
  color: #bdbdbd;
  opacity: 1;
  /* Firefox */ }

.Input_input-box__1q83J.Input_disabled__22_7z input::placeholder {
  color: #bdbdbd;
  opacity: 1;
  /* Firefox */ }

.Input_input-box__1q83J.Input_disabled__22_7z input:-ms-input-placeholder, .Input_input-box__1q83J.Input_disabled__22_7z input::-ms-input-placeholder {
  color: #bdbdbd; }

.CuppingForm_form__1uAOz {
  background: white;
  padding: 0.625rem;
  width: 90%;
  max-width: 67.5rem;
  margin: auto; }

.CuppingForm_form__1uAOz .CuppingForm_row__3lA_r {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row; }

.CuppingForm_form__1uAOz .CuppingForm_cdr-3__2gAfh * {
  -webkit-flex: 1;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: 1 1; }

.CuppingForm_form__1uAOz > table {
  width: 100%;
  border: 1px solid #DDDDDD; }

.CuppingForm_form__1uAOz > table td.CuppingForm_no-border__3uzNM {
  background: white !important;
  color: black !important;
  border: none !important; }

.CuppingForm_form__1uAOz > table td.CuppingForm_disabled__1X_UE {
  color: #c3c3c3;
  cursor: not-allowed; }

.CuppingForm_form__1uAOz > table .CuppingForm_empty__3Xm9z {
  height: 2.5rem; }

.CuppingForm_form__1uAOz > table > thead > tr > td {
  font-weight: 700; }

.CuppingForm_form__1uAOz > table > tbody > tr > td {
  width: 9.375rem; }

.CuppingForm_form__1uAOz > table > tbody > tr > td > input, .CuppingForm_form__1uAOz > table > tbody > tr > td > select {
  width: 100%;
  border: 1px solid #BDBDBD; }

.CuppingForm_form__1uAOz > table > tbody > tr .CuppingForm_remove-btn__USjCg {
  width: 3.125rem;
  background: #BDBDBD;
  color: white;
  text-align: center;
  cursor: pointer; }

.CuppingForm_form__1uAOz > table > tbody > tr .CuppingForm_remove-btn__USjCg:hover {
  background: #a4a4a4; }

.CuppingForm_form__1uAOz > table > tbody > tr .CuppingForm_bold__3-a24 {
  font-weight: 700; }

.CuppingForm_form__1uAOz > table > tbody > tr .CuppingForm_total__1W0u- {
  text-align: right; }

.CuppingForm_form__1uAOz > table > tbody > tr .CuppingForm_notes__2I555 > textarea {
  width: 100%;
  min-height: 3.75rem; }

.CuppingForm_form__1uAOz > table > tbody > tr .CuppingForm_notes__2I555 .CuppingForm_note-list__2fOqn {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 0.625rem; }

.CuppingForm_form__1uAOz > table > tbody > tr .CuppingForm_notes__2I555 .CuppingForm_note-list__2fOqn .CuppingForm_item__3SW7C {
  background: #008EFB;
  padding: 0.3125rem 0.625rem;
  color: white;
  font-size: 0.875rem;
  border-radius: 999px;
  margin-right: 0.3125rem; }

.CuppingForm_form__1uAOz > table > tbody .CuppingForm_plus-row__y9avw {
  border-spacing: 5px 0;
  background: #BDBDBD;
  height: 3.75rem;
  cursor: pointer;
  margin: 0.3125rem 0; }

.CuppingForm_form__1uAOz > table > tbody .CuppingForm_plus-row__y9avw > h1 {
  color: white;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 3.75rem; }

.CuppingForm_form__1uAOz > table > tbody .CuppingForm_plus-row__y9avw:hover {
  background: #a4a4a4; }

.CuppingForm_form__1uAOz > table > tbody .CuppingForm_none_input-row__ouG8u > td {
  background: #ebebeb;
  color: #525252; }

.CuppingForm_form__1uAOz > h3 {
  margin-top: 1.875rem; }

.CuppingForm_btn-wrapper__JfYed {
  margin: 2.5rem 0; }

.CuppingForm_btn-wrapper__JfYed .CuppingForm_row__3lA_r {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row; }

.CuppingForm_btn-wrapper__JfYed .CuppingForm_row__3lA_r > button {
  -webkit-flex: 0 0 20%;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: 0 0 20%;
  background: none;
  border: 1px solid #DDDDDD;
  padding: 0.625rem 0;
  cursor: pointer; }

.CuppingForm_btn-wrapper__JfYed .CuppingForm_row__3lA_r .CuppingForm_cancel__Ra1Ba {
  background: none;
  color: #FF4A22; }

.CuppingForm_btn-wrapper__JfYed .CuppingForm_row__3lA_r .CuppingForm_submit__gyD8U {
  border: none;
  background: #008EFB;
  color: white; }

.CuppingForm_btn-wrapper__JfYed .CuppingForm_error-message__1i7l1 {
  color: #FF4A22; }

.GGForm_form__2qmri {
  padding: 1.25rem;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 1.25rem; }

.GGForm_form__2qmri > .GGForm_basic__1qHV9 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 2.5rem; }

.GGForm_form__2qmri > .GGForm_basic__1qHV9 > div + div {
  margin-left: 1.25rem; }

.GGForm_form__2qmri > h1 {
  margin-bottom: 0.625rem; }

.GGForm_form__2qmri .GGForm_row__YVBcZ {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 2.5rem; }

.GGForm_form__2qmri .GGForm_row__YVBcZ > :nth-child(2) {
  margin: 0 1.25rem; }

.AddSamples_form_summary-template__107pY {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 1.25rem; }

.AddSamples_form_summary-template__107pY > :first-child {
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  min-width: 60rem;
  -webkit-flex: 1 1 50rem;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: 1 1 50rem;
  margin-right: 1.25rem;
  padding: 1.25rem; }

.AddSamples_form_summary-template__107pY > :nth-child(2) {
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  -webkit-flex: auto;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: auto;
  padding: 1.25rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 25rem;
  position: -webkit-sticky;
  position: sticky;
  top: 100px; }

.AddSamples_form_summary-template__107pY .AddSamples_form__18A6K > h1 {
  margin-bottom: 2.5rem; }

.AddSamples_form_summary-template__107pY .AddSamples_form__18A6K .AddSamples_row-cdr-2__3zBtA {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify; }

.AddSamples_form_summary-template__107pY .AddSamples_form__18A6K .AddSamples_row-cdr-2__3zBtA > * {
  -webkit-flex: 0 0 49%;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: 0 0 49%; }

.AddSamples_form_summary-template__107pY .AddSamples_summary__3MDSz > h3 {
  margin-bottom: 1.25rem; }

.AddSamples_form_summary-template__107pY .AddSamples_summary__3MDSz > p {
  margin-bottom: 0.625rem; }

.AddSamples_form_summary-template__107pY .AddSamples_summary__3MDSz .AddSamples_divider__1nFgQ {
  border: 1px solid #DDDDDD;
  margin: 2.5rem 0; }

.AddSamples_form_summary-template__107pY .AddSamples_summary__3MDSz .AddSamples_row-cdr-2__3zBtA {
  margin-top: 2.5rem;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -ms-flex-pack: justify; }

.AddSamples_form_summary-template__107pY .AddSamples_summary__3MDSz .AddSamples_row-cdr-2__3zBtA > * {
  -webkit-flex: 0 0 49%;
  /* Safari 6.1+ */
  /* IE 10 */
  flex: 0 0 49%; }

.AddSamples_form_summary-template__107pY .AddSamples_summary__3MDSz .AddSamples_row-cdr-2__3zBtA > button {
  padding: 0.8125rem 0; }

.AddSamples_form_summary-template__107pY .AddSamples_summary__3MDSz .AddSamples_row-cdr-2__3zBtA > button.AddSamples_submit__2IMx8 {
  background: #008EFB;
  color: white; }

.pagination {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-flex-direction: row;
          flex-direction: row;
  padding-left: 0;
  list-style: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 3.125rem; }
  .pagination > li {
    margin-right: 0.3125rem;
    padding: 0.625rem 0;
    background: white; }
    .pagination > li > a {
      padding: 0.625rem 1.25rem; }
  .pagination .active {
    background: #008EFB; }
    .pagination .active > a {
      color: white; }

