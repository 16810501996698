.form_summary-template {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: row;
  -ms-flex-direction: row;
  margin-top: 1.25rem; }
  .form_summary-template > :first-child {
    background: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    min-width: 60rem;
    -webkit-flex: 1 1 50rem;
    /* Safari 6.1+ */
    -ms-flex: 1 1 50rem;
    /* IE 10 */
    flex: 1 1 50rem;
    margin-right: 1.25rem;
    padding: 1.25rem; }
  .form_summary-template > :nth-child(2) {
    background: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    -webkit-flex: auto;
    /* Safari 6.1+ */
    -ms-flex: auto;
    /* IE 10 */
    flex: auto;
    padding: 1.25rem;
    height: fit-content;
    min-height: 25rem;
    position: sticky;
    top: 100px; }
  .form_summary-template .form > h1 {
    margin-bottom: 2.5rem; }
  .form_summary-template .form .row-cdr-2 {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: space-between;
    -ms-flex-pack: justify; }
    .form_summary-template .form .row-cdr-2 > * {
      -webkit-flex: 0 0 49%;
      /* Safari 6.1+ */
      -ms-flex: 0 0 49%;
      /* IE 10 */
      flex: 0 0 49%; }
  .form_summary-template .summary > h3 {
    margin-bottom: 1.25rem; }
  .form_summary-template .summary > p {
    margin-bottom: 0.625rem; }
  .form_summary-template .summary .divider {
    border: 1px solid #DDDDDD;
    margin: 2.5rem 0; }
  .form_summary-template .summary .row-cdr-2 {
    margin-top: 2.5rem;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: space-between;
    -ms-flex-pack: justify; }
    .form_summary-template .summary .row-cdr-2 > * {
      -webkit-flex: 0 0 49%;
      /* Safari 6.1+ */
      -ms-flex: 0 0 49%;
      /* IE 10 */
      flex: 0 0 49%; }
    .form_summary-template .summary .row-cdr-2 > button {
      padding: 0.8125rem 0; }
    .form_summary-template .summary .row-cdr-2 > button.submit {
      background: #008EFB;
      color: white; }
