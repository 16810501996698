.form {
  background: white;
  padding: 0.625rem;
  width: 90%;
  max-width: 67.5rem;
  margin: auto; }
  .form .row {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: row;
    -ms-flex-direction: row; }
  .form .cdr-3 * {
    -webkit-flex: 1;
    /* Safari 6.1+ */
    -ms-flex: 1;
    /* IE 10 */
    flex: 1; }
  .form > table {
    width: 100%;
    border: 1px solid #DDDDDD; }
    .form > table td.no-border {
      background: white !important;
      color: black !important;
      border: none !important; }
    .form > table td.disabled {
      color: #c3c3c3;
      cursor: not-allowed; }
    .form > table .empty {
      height: 2.5rem; }
    .form > table > thead > tr > td {
      font-weight: 700; }
    .form > table > tbody > tr > td {
      width: 9.375rem; }
      .form > table > tbody > tr > td > input, .form > table > tbody > tr > td > select {
        width: 100%;
        border: 1px solid #BDBDBD; }
    .form > table > tbody > tr .remove-btn {
      width: 3.125rem;
      background: #BDBDBD;
      color: white;
      text-align: center;
      cursor: pointer; }
      .form > table > tbody > tr .remove-btn:hover {
        background: #a4a4a4; }
    .form > table > tbody > tr .bold {
      font-weight: 700; }
    .form > table > tbody > tr .total {
      text-align: right; }
    .form > table > tbody > tr .notes > textarea {
      width: 100%;
      min-height: 3.75rem; }
    .form > table > tbody > tr .notes .note-list {
      display: -webkit-box;
      /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box;
      /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;
      /* TWEENER - IE 10 */
      display: -webkit-flex;
      /* NEW - Chrome */
      display: flex;
      /* NEW, Spec - Opera 12.1, Firefox 20+ */
      flex-direction: row;
      -ms-flex-direction: row;
      margin-top: 0.625rem; }
      .form > table > tbody > tr .notes .note-list .item {
        background: #008EFB;
        padding: 0.3125rem 0.625rem;
        color: white;
        font-size: 0.875rem;
        border-radius: 999px;
        margin-right: 0.3125rem; }
    .form > table > tbody .plus-row {
      border-spacing: 5px 0;
      background: #BDBDBD;
      height: 3.75rem;
      cursor: pointer;
      margin: 0.3125rem 0; }
      .form > table > tbody .plus-row > h1 {
        color: white;
        text-align: center;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 3.75rem; }
      .form > table > tbody .plus-row:hover {
        background: #a4a4a4; }
    .form > table > tbody .none_input-row > td {
      background: #ebebeb;
      color: #525252; }
  .form > h3 {
    margin-top: 1.875rem; }

.btn-wrapper {
  margin: 2.5rem 0; }
  .btn-wrapper .row {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: row;
    -ms-flex-direction: row; }
    .btn-wrapper .row > button {
      -webkit-flex: 0 0 20%;
      /* Safari 6.1+ */
      -ms-flex: 0 0 20%;
      /* IE 10 */
      flex: 0 0 20%;
      background: none;
      border: 1px solid #DDDDDD;
      padding: 0.625rem 0;
      cursor: pointer; }
    .btn-wrapper .row .cancel {
      background: none;
      color: #FF4A22; }
    .btn-wrapper .row .submit {
      border: none;
      background: #008EFB;
      color: white; }
  .btn-wrapper .error-message {
    color: #FF4A22; }
