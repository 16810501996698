.warning-text {
  color: red;
  position: absolute; }

.input-label {
  font-size: 13px;
  color: #3F3F3F;
  font-weight: 600;
  margin-bottom: 5px; }

.form-require-circle {
  width: 6px;
  height: 6px;
  background-color: #0072ce;
  border-radius: 100px;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 1px; }

.input-disabled {
  background: #ebebe4 !important;
  color: #9a9a9a !important;
  cursor: default !important;
  pointer-events: none !important;
  opacity: .45 !important; }

.marginLeft {
  margin-left: 20px; }

.fit {
  width: fit-content; }

.input-box {
  width: 100%;
  min-height: 50px;
  position: relative;
  background: #FAFAFA;
  width: 100%;
  padding: 13px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #3D3D3D;
  font-family: 'Montserrat';
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: row;
  -ms-flex-direction: row;
  justify-content: space-between;
  -ms-flex-pack: justify;
  align-items: center;
  -ms-flex-align: center; }
  .input-box input {
    width: 100%;
    border: none; }
    .input-box input:focus {
      outline: none; }
    .input-box input::placeholder {
      color: #929292;
      opacity: 1;
      /* Firefox */ }
    .input-box input:-ms-input-placeholder, .input-box input::-ms-input-placeholder {
      color: #929292; }
  .input-box span {
    -webkit-flex: none;
    /* Safari 6.1+ */
    -ms-flex: none;
    /* IE 10 */
    flex: none;
    color: #9e9e9e; }

.input-box.error {
  border-color: #FF4A22; }

.input-box.focus {
  border-color: #008EFB; }

.input-box.disabled {
  pointer-events: none;
  border-color: #bdbdbd;
  color: #3D3D3D;
  background: #eeeeee; }
  .input-box.disabled input {
    background: #eeeeee; }
    .input-box.disabled input::placeholder {
      color: #bdbdbd;
      opacity: 1;
      /* Firefox */ }
    .input-box.disabled input:-ms-input-placeholder, .input-box.disabled input::-ms-input-placeholder {
      color: #bdbdbd; }
