.form {
  padding: 1.25rem;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 1.25rem; }
  .form > .basic {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem; }
    .form > .basic > div + div {
      margin-left: 1.25rem; }
  .form > h1 {
    margin-bottom: 0.625rem; }
  .form .row {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: row;
    -ms-flex-direction: row;
    margin-bottom: 2.5rem; }
    .form .row > :nth-child(2) {
      margin: 0 1.25rem; }
