.pagination {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: row;
  -ms-flex-direction: row;
  padding-left: 0;
  list-style: none;
  width: fit-content;
  margin: 0 auto 3.125rem; }
  .pagination > li {
    margin-right: 0.3125rem;
    padding: 0.625rem 0;
    background: white; }
    .pagination > li > a {
      padding: 0.625rem 1.25rem; }
  .pagination .active {
    background: #008EFB; }
    .pagination .active > a {
      color: white; }
